import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginPage from '../pages/LoginPage.vue';
import Home from "@/pages/Home.vue";
import RegisterPage from "@/pages/RegisterPage.vue";
import UserList from "@/pages/UserList.vue";
import UserUpdatePage from "@/pages/UserUpdatePage.vue";
import HoaChatHetHanPage from "@/pages/HoaChatHetHanPage.vue";
import ThongTinChiTietHoaChatPage from "@/pages/ThongTinChiTietHoaChatPage.vue";
import CapNhatThongTinChiTiet from "@/pages/CapNhatThongTinChiTiet.vue";
import caiDatXetNghiem from "@/pages/CaiDatXetNghiem.vue";
import themHoaChat from "@/pages/ThemHoaChat.vue";
import DSXetNghiem from "@/pages/DSXetNghiem.vue";
import DSTonKho from "@/pages/DSTonKho.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    props: true
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    props: true
  },
  {
    path: '/hoachathethan',
    name: 'hoachathethan',
    component: HoaChatHetHanPage,
    props: true
  },
  {
    path: '/dstonkho',
    name: 'dstonkho',
    component: DSTonKho,
    props: true
  },
  {
    path: '/capnhatthongtinchitiet/:id',
    name: 'capnhatthongtinchitiet',
    component: CapNhatThongTinChiTiet,
    props: true
  },
  {
    path: '/thongtinchitiet',
    name: 'thongtinchitiet',
    component: ThongTinChiTietHoaChatPage,
    props: true
  },
  {
    path: '/caidatxetnghiem',
    name: 'caidatxetnghiem',
    component: caiDatXetNghiem,
    props: true
  },
  {
    path: '/themhoachat',
    name: 'themhoachat',
    component: themHoaChat,
    props: true
  },
  {
    path: '/dsxetnghiem',
    name: 'dsxetnghiem',
    component: DSXetNghiem,
    props: true
  },
  {
    path: '/registerpage',
    name: 'registerpage',
    component: RegisterPage,
    props: true
  },
  {
    path: '/userlist',
    name: 'userlist',
    component: UserList,
    props: true
  },
  {
    path: '/userupdate/:id',
    name: 'userupdate',
    component: UserUpdatePage,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
