
import {computed, onMounted, ref} from 'vue';
    import {useToast} from "primevue/usetoast";
    import axios from "axios";
    import {useConfirm} from "primevue/useconfirm";
import {ThanhPhanCreate} from "@/models/thanhPhanCreate";
import {InitXetNghiem} from "@/models/initXetNghiem";
import HoaChatRepository from "@/services/HoaChatRepository";
import {XetNghiem} from "@/models/xetNghiem";
import {HoaChatInfo} from "@/models/hoaChatInfo";

    export default {
        setup() {
            const showDetail = ref(false);
            const initXN = ref({} as InitXetNghiem);
            const loaiXN = ref([] as XetNghiem[]);
            const toast = useToast();
            const list = ref([] as ThanhPhanCreate[]);
            const listHoaChat = ref([] as HoaChatInfo[]);
            const loadingBar = ref(false);


          const donVi = ref([
            {label: "ml", value: "ml", param: 1},
            {label: "test", value: "test", param: 2},
            {label: "hộp", value: "hộp", param: 3},
            {label: "thùng", value: "thùng", param: 4},
          ]);

          HoaChatRepository.dsXetNghiem()
              .then((response) => {
                loaiXN.value = response.data;
              })
              .catch(error => {
                loadingBar.value = false;
              });

          const getThanhPhan = (id) => {
            HoaChatRepository.getThanhPhan(id)
                .then((response) => {
                  initXN.value = response.data;
                  list.value = initXN.value.thanhPhans;
                })
                .catch(error => {
                });
          }

          HoaChatRepository.dsHoaChat()
              .then((response) => {
                listHoaChat.value = response.data;
              })
              .catch(error => {
                loadingBar.value = false;
              })

            const add = () => {
                {
                  // list.value.forEach(x => {
                  //   listHoaChat.value.filter(y => {
                  //     if(y.tenHoaChatDayDu == x.nhomHoaChat) x.donViTinh = y.donViTinh;
                  //   })
                  // })
                  initXN.value.thanhPhans = list.value;
                        HoaChatRepository.createThanhPhan(initXN.value)
                            .then((response) => {
                              const temp = ref({} as InitXetNghiem);
                              initXN.value = temp.value;
                              list.value = [];
                              toast.add({
                                severity: 'success',
                                summary: 'Thành công',
                                detail: 'Cài đặt xét nghiệm thành công',
                                life: 2000
                              });
                            })
                            .catch(error => {
                                loadingBar.value = false;
                            })
                }
            };



            const addInfo = () => {
                console.log("###########@@@@@@@@@@@$$$$$$$$$$$$$$$$$$$$ addInfo list: " + JSON.stringify(list.value));
                const add = ref({} as ThanhPhanCreate);
                list.value.push(add.value);
            };
            const minusInfo = (minus: any) => {
              console.log("###########@@@@@@@@@@@$$$$$$$$$$$$$$$$$$$$ addInfo minus: " + JSON.stringify(minus));
              // list.value.findIndex(x => x.name == minus.name)
              list.value.splice(list.value.findIndex(x => x.nhomHoaChat == minus.nhomHoaChat), 1);
            };
            addInfo();

          const valid = computed(() => initXN.value.id)

          const validValue= computed(() => {
            const check = ref(true);
            if(list.value.length > 0) {
              list.value.forEach(x => {
                check.value = check.value && (x.hamLuong > 0) && (x.nhomHoaChat.length > 0) && (x.donViTinh != null);
              });
              return check.value;
            }
            else
            {
              return false;
            }
          })

            return {
                showDetail,
                list,
                addInfo,
                minusInfo,
              add,
              initXN,
              listHoaChat,
              loaiXN,
              donVi,
              valid,
              validValue,
              getThanhPhan,
            }
        }

    }
