

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router';
import {useStore} from "vuex";
import {useConfirm} from "primevue/useconfirm";
import {UserUpdate} from "@/models/userUpdate";
import {ThongTinChiTietUpdate} from "@/models/thongTinChiTietUpdate";
import hoaChatRepository from "@/services/HoaChatRepository";
import {LoaiHoaChatCreate} from "@/models/loaiHoaChatCreate";

export default {
  props: {
    id: Number,
  },

  setup(props) {
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const thongTinChiTiet= ref({} as ThongTinChiTietUpdate);
    const listLoaiHoaChat = ref([] as LoaiHoaChatCreate[]);

    const donVi = ref([
      {label: "ml", value: "ml", param: 1},
      {label: "test", value: "test", param: 2},
      {label: "hộp", value: "hộp", param: 3},
      {label: "thùng", value: "thùng", param: 4},
    ]);

    hoaChatRepository.thongTinChiTietById(props.id)
    .then((response) => {
      thongTinChiTiet.value = response.data;
    })
    .catch(err => {
      toast.add({
        severity: 'error',
        summary: 'Lỗi',
        detail:err.response.data,
        life: 2000
      });
    });

    hoaChatRepository.dsLoaiHoaChat()
        .then((response) => {
          listLoaiHoaChat.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 2000
          });
        });

    const valid = computed(()=> thongTinChiTiet.value.tenHoaChat
        && thongTinChiTiet.value.nhomHoaChat && thongTinChiTiet.value.hamLuong && thongTinChiTiet.value.donViTinh);

    //
    const doUpdate = () => {
        hoaChatRepository.capNhatThongTinChiTiet(thongTinChiTiet.value)
                .then((response) => {
                  console.log("#######$$$$$$$$$$$%%%%%%%%%%%%%%%%% doUpdate222222: " + JSON.stringify(thongTinChiTiet.value));
                  toast.add({
                    severity: 'success',
                    summary: 'Cập nhật',
                    detail: 'Cập nhật thông tin tài khoản thành công',
                    life: 2000
                  });
                  // router.push({
                  //   name: 'home'
                  // });
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
    };

    return {
      valid,
      error,
      donVi,
      thongTinChiTiet,
      doUpdate,
      listLoaiHoaChat
    }
  }
}

