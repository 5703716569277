

  import { computed, ref } from 'vue'
  import moment from 'moment';
  import {FilterMatchMode, FilterOperator} from "primevue/api";
  import router from "@/router";
  import {useToast} from "primevue/usetoast";
  import {useStore} from "vuex";
  import axios from "axios";
  import hoaChatRepository from "@/services/HoaChatRepository";
  import {useRouter} from "vue-router";
  import {DsXetNghiem} from "@/models/dsXetNghiem";
  import {Response} from "@/models/Response";
  import {ThucHienXetNghiem} from "@/models/thucHienXetNghiem";

  export default {
    setup() {
      const toast = useToast();
      const store = useStore();
      const list = ref([] as DsXetNghiem[]);
      const listChuaThucHien = ref([] as DsXetNghiem[]);
      const date = ref();
      const date1 = ref();
      const service = ref("");
      const router = useRouter();
      const kind = ref(0);
      const fileName = ref("");
      const loadingBar = ref(false);
      const downloadFile = ref(false);

      if(!(!!store.state.token)){
        router.push({
          name: 'home'
        });
      }

      const selectCalendar = () => {
        if((date.value[0] != null)&&(date.value[1] != null))
        {
          list.value = [];
          downloadFile.value = false;
          hoaChatRepository.dsThucHienXetNghiem(date.value[0]/1000, date.value[1]/1000)
              .then((response) => {
               list.value = response.data;
               list.value.forEach(a => {
                 a.tongTien = 0;
                 a.dsXetNghiems.forEach(x => a.tongTien = a.tongTien + (x.donGia==null ?0 : x.donGia));
                 const i = ref(0);
                 a.dsXetNghiems.forEach(x => {
                   x.id = i.value + 1;
                   i.value = x.id;
                 });
               })
                // console.log("@@@@@@@@@################ dsThucHienXetNghiem list: " + JSON.stringify(list.value));
              })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail:err.response.data,
                  life: 2500
                })})
        }
      };

      //selectCalendar();

      const clearCalendar = () => {
        list.value = [];
        downloadFile.value = false;
      }

      const selectCalendarUnaccomplished  = () => {
        if((date1.value[0] != null)&&(date1.value[1] != null))
        {
          listChuaThucHien.value = [];
          downloadFile.value = false;
          hoaChatRepository.dsThucHienXetNghiemChuaThucHien(date1.value[0]/1000, date1.value[1]/1000)
              .then((response) => {
                listChuaThucHien.value = response.data;
                // console.log("@@@@@@@@@################ list: " + JSON.stringify(listChuaThucHien.value));
                listChuaThucHien.value.forEach(a => {
                  a.tongTien = 0;
                  a.dsXetNghiems.forEach(x => a.tongTien = a.tongTien + (x.donGia==null ?0 : x.donGia));
                  const i = ref(0);
                  a.dsXetNghiems.forEach(x => {
                    x.id = i.value + 1;
                    i.value = x.id;
                  })
                })
              })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail:err.response.data,
                  life: 2500
                })})
        }
      };

      //selectCalendar();

      const clearCalendarUnaccomplished = () => {
        listChuaThucHien.value = [];
        downloadFile.value = false;
      }
      const formatDateTime = (date) => {
        if((date == null)||(date == ''))
        {
          return "chưa có thông tin"
        }
        else
        return moment(String(date)).format('DD/MM/YYYY');
      };

      const formatDateTimeCheckIn = (date) => {
        if((date == null)||(date == ''))
        {
          return "chưa có thông tin";
        }
        else
          return moment(String(date)).format('HH:mm DD/MM/YYYY');
      };

      const clearDate = () => {
        // console.log("@@@@@@@@@################ clearDate");
        date.value = new Date();
      }

      const filters = ref({
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      });

      const filters2 = ref({
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      });

      const clearFilter = () => {
        initFilters();
      };

      const initFilters = () => {
        filters.value = {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        }
        filters2.value = {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        }
        filtersContent.value = {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        }
        filtersContent2.value = {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        }
      };

      const filtersContent = ref({
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      });

      const filtersContent2 = ref({
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      });

      const clearFilterContent = () => {
        initFilters();
      };

      const initFiltersContent = () => {
        filters.value = {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        }
      };

      const downLoadFileExport = () => {
        axios({
          url: '../api/import/FileImport/downloadListBooking/' + fileName.value,
          method: 'GET',
          responseType: 'blob',
        })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download',"DanhSachDatLich.xlsx");
              document.body.appendChild(link);
              link.click();
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })});
      };
      const expandedRows = ref();

      const onRowExpand = (event) => {
        //toast.add({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
      };
      const onRowCollapse = (event) => {
       // toast.add({severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000});
      };
      const expandAll = () => {
        expandedRows.value = list.value.filter(p => p.maXetNghiem);
        //toast.add({severity: 'success', summary: 'All Rows Expanded', life: 3000});
      };
      const collapseAll = () => {
        expandedRows.value = [];
        //toast.add({severity: 'success', summary: 'All Rows Collapsed', life: 3000});
      };

      const responseSync = ref({} as Response);
      const resyncXetNghiem = (id) => {
        hoaChatRepository.dongBoLaiXeNghiem(id)
            .then((response) => {
              responseSync.value = response.data;
              if(responseSync.value.code == -1)
              {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail:responseSync.value.message,
                  life: 2500
                });
              }
              else {
                toast.add({
                  severity: 'success',
                  summary: 'Thành công',
                  detail: 'Thực hiện thành công, xét nghiệm đã thực hiện và thống kê',
                  life: 2500
                });
                selectCalendarUnaccomplished();
                selectCalendar();
              }
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })})
      }

      const resyncXetNghiemAll = () => {
        hoaChatRepository.dongBoLaiXeNghiemAll()
            .then((response) => {
                toast.add({
                  severity: 'success',
                  summary: 'Thành công',
                  detail: 'Thực hiện thành công, xét nghiệm đã thực hiện và thống kê',
                  life: 2500
                });
                selectCalendarUnaccomplished();
                selectCalendar();
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })})
      }

      const moneyValue = (x) => {
        return Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(x);
      }

      const totalPriceXN = computed(() => {
        const total = ref(0);
        list.value.forEach(x => total.value = total.value + x.tongTien);
        return moneyValue(total.value);
      })

      const totalPriceUnXN = computed(() => {
        const total = ref(0);
        listChuaThucHien.value.forEach(x => total.value = total.value + x.tongTien);
        return moneyValue(total.value);
      })

      return {
        formatDateTime,
        list,
        date,
        selectCalendar,
        clearCalendar,
        service,
        kind,
        clearDate,
        filters,
        clearFilter,
        filtersContent,
        filters2,
        filtersContent2,
        clearFilterContent,
        formatDateTimeCheckIn,
        loadingBar,
        downloadFile,
        downLoadFileExport,
        expandedRows, onRowExpand, onRowCollapse, expandAll, collapseAll,
        listChuaThucHien,
        selectCalendarUnaccomplished,
        clearCalendarUnaccomplished,
        date1,
        resyncXetNghiem,
        resyncXetNghiemAll,
        moneyValue,
        totalPriceXN,
        totalPriceUnXN
      }
    }

  }
