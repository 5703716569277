import Repository from './Repository';
import {AxiosResponse} from "axios";
import {ThongTinChiTietUpdate} from "@/models/thongTinChiTietUpdate";
import {InitXetNghiem} from "@/models/initXetNghiem";
import {KhoCreate} from "@/models/khoCreate";
import {LoaiHoaChatCreate} from "@/models/loaiHoaChatCreate";

const resource = '/hoachat';

export default new class{
    dsThuocHetHan(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/dsThuocHetHan`);
    }
    dsThongTinChiTietMoi(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/dsThongTinChiTietMoi`);
    }
    dsThongTinChiTietDaCaiDat(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/dsThongTinChiTietDaCaiDat`);
    }
    thongTinChiTietById(id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/thongTinChiTiet/${id}`);
    }
    capNhatThongTinChiTiet(thongTinChiTietUpdate: ThongTinChiTietUpdate): Promise<AxiosResponse<any>>{
        return Repository.put<any>(`${resource}/capNhatThongTinChiTiet`, thongTinChiTietUpdate);
    }
    dsHoaChat(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/dsHoaChat`);
    }
    dsXetNghiem(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/dsXetNghiem`);
    }
    createThanhPhan(initXetNghiem: InitXetNghiem): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/createThanhPhan`, initXetNghiem);
    }
    createKho(khoCreate: KhoCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/taoHoaChat`, khoCreate);
    }
    createLoaiHoaChat(loaiHoaChatCreate: LoaiHoaChatCreate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/taoLoaiHoaChat`, loaiHoaChatCreate);
    }
    dsLoaiHoaChat(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/dsLoaiHoaChat`);
    }
    dsThucHienXetNghiem(dt1: number, dt2: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/listThucHienXetNghiem/${dt1}/${dt2}`);
    }
    dsBaoCaoKho(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/listBaoCaoKho`);
    }
    dsBaoCaoKhoQuaHan(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/listBaoCaoKhoQuaHan`);
    }
    dsThucHienXetNghiemChuaThucHien(dt1: number, dt2: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/listThucHienXetNghiemChuaThucHien/${dt1}/${dt2}`);
    }
    dongBoLaiXeNghiem(id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/dongBoLaiXetNghiem/${id}`);
    }
    dongBoLaiXeNghiemAll(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/dongBoLaiXetNghiemAll`);
    }
    deleteLoaiHoaChat(id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/deleteLoaiHoaChat/${id}`);
    }
    getThanhPhan(id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getThanhPhan/${id}`);
    }
}
