
import {computed, ref} from 'vue'
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import router from "@/router";

export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const defaultMenu = [
    ];
    const userMenu = [
      {
        label:'Thêm tỷ lệ hao hụt',
        icon:'pi pi-fw pi-plus-circle',
        url: '/drugcreatepage'
      },
      {
        label:'Thực hiện bào chế thuốc',
        icon:'pi pi-fw pi-sync',
        url: '/detailpage'
      },
      // {
      //   label:'Thống kê ds thuốc bào chế',
      //   icon:'pi pi-fw pi-list',
      //   url: '/statisticalpage'
      // }
    ];
    const adminMenu = [
      {
        label:'Danh sách hoá chất sắp hết hạn',
        icon:'pi pi-fw pi-list',
        url: '/hoachathethan'
      },
      {
        label:'Danh sách hóa chất',
        icon:'pi pi-fw pi-list',
        url: '/thongtinchitiet'
      },
      {
        label:'Danh sách xét nghiệm',
        icon:'pi pi-fw pi-list',
        url: '/dsxetnghiem'
      },
      {
        label:'Thống kê kho',
        icon:'pi pi-fw pi-database',
        url: '/dstonkho'
      },
      {
        label:'Cài đặt xét nghiệm',
        icon:'pi pi-fw pi-wrench',
        url: '/caidatxetnghiem'
      },
      {
        label:'Thêm hóa chất',
        icon:'pi pi-fw pi-plus',
        url: '/themhoachat'
      },
      {
        label:'Danh sách tài khoản',
        icon:'pi pi-fw pi-users',
        url: '/userlist'
      },
      {
        label:'Đăng ký tài khoản mới',
        icon:'pi pi-fw pi-user-plus',
        url: '/registerpage'
      }
    ];
    const appMenu = computed(() => {
      if(store.state.permission == "admin") return adminMenu;
      else if(store.state.permission == "user") return userMenu;
      else return defaultMenu;
    });
    const logout = () => {
      store.dispatch('clearToken');
      store.dispatch('clearPermission');
      router.push({
        name: 'login',
      });
      toast.add({
        severity:'success',
        summary: 'Đăng xuất',
        detail:'Đã xóa thông tin đăng nhập thành công',
        life: 1000
      });
    }

    return {
      appMenu,
      logout
    }
  }
}
