

  import { computed, ref } from 'vue';
  import {useToast} from "primevue/usetoast";
  import moment from 'moment';
  import {Kho} from "@/models/kho";
  import HoaChatRepository from "@/services/HoaChatRepository";
  import {ThongTinChiTiet} from "@/models/thongTinChiTiet";
  import {FilterMatchMode, FilterOperator} from "primevue/api";
  import {LoaiHoaChatCreate} from "@/models/loaiHoaChatCreate";
  import {LoaiHoaChat} from "@/models/loaiHoaChat";

  export default {
    setup() {
    const ds = ref([] as ThongTinChiTiet[]);
    const ds2 = ref([] as ThongTinChiTiet[]);
    const toast = useToast();
    const loaiHoaChat = ref({} as LoaiHoaChatCreate);
    const listLoaiHoaChat = ref([] as LoaiHoaChat[]);
    const nhomHoaChat = ref("");

        HoaChatRepository.dsThongTinChiTietMoi()
            .then((response) => {
                console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ HoaChat het han: " + JSON.stringify(response.data));
                ds.value = response.data;
            })
            .catch(err => {
                toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                });
            });

        const GetLoaiHoaChat = () => {
          HoaChatRepository.dsLoaiHoaChat()
              .then((response) => {
                console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ LoaiHoaChat list: " + JSON.stringify(response.data));
                listLoaiHoaChat.value = response.data;
              })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail:err.response.data,
                  life: 2000
                });
              });
        }
      GetLoaiHoaChat();

      HoaChatRepository.dsThongTinChiTietDaCaiDat()
          .then((response) => {
            console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ HoaChat het han: " + JSON.stringify(response.data));
            ds2.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });

      const themLoaiHoaChat = () => {
        loaiHoaChat.value.nhomHoaChat = nhomHoaChat.value;
        HoaChatRepository.createLoaiHoaChat(loaiHoaChat.value)
            .then((response) => {
             // console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ HoaChat het han: " + JSON.stringify(response.data));
              //ds2.value = response.data;
              GetLoaiHoaChat();
              toast.add({
                severity: 'success',
                summary: 'Thành công',
                detail: 'Thêm thành công hóa chất',
                life: 2000
              });
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            });
      }

      const formatDateTime = (date) => {
        return moment(String(date)).format('DD/MM/YYYY');
      };
      const filters = ref({
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      });

      const initFilters = () => {
        filters.value = {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        }
      };
      const clearFilter = () => {
        initFilters();
      };

      const valid = computed(()=> nhomHoaChat.value && nhomHoaChat.value);

      return {
        ds,
        formatDateTime,
        filters,
        clearFilter,
        ds2,
        loaiHoaChat,
        themLoaiHoaChat,
        listLoaiHoaChat,
        nhomHoaChat,
        valid
      }
    }

  }
