

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import {KhoCreate} from "@/models/khoCreate";
import HoaChatRepository from "@/services/HoaChatRepository";
import {LoaiHoaChat} from "@/models/loaiHoaChat";

export default {

  setup(){
    const router = useRouter();
    const hoaChat = ref({} as KhoCreate);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const emailPer = ref("");
    const dateExp = ref();
    const dateIn = ref();
    const listLoaiHoaChat = ref([] as LoaiHoaChat[]);

    const donVi = ref([
      {label: "ml", value: "ml", param: 1},
      {label: "test", value: "test", param: 2},
      {label: "hộp", value: "hộp", param: 3},
      {label: "thùng", value: "thùng", param: 4},
    ]);

    // const valid = computed(()=> register.value.password && register.value.username  && register.value.fullname);

    if(!(store.state.permission == 'admin')){
      router.push({
        name: 'home'
      });
    }

    const GetLoaiHoaChat = () => {
      HoaChatRepository.dsLoaiHoaChat()
          .then((response) => {
            console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ LoaiHoaChat list: " + JSON.stringify(response.data));
            listLoaiHoaChat.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }
    GetLoaiHoaChat();
    // register.value.permission = 'user';

    const doRegister = () => {
      // if(register.value.password.length < 6)
      // {
      //   toast.add({
      //     severity: 'error',
      //     summary: 'Thất bại',
      //     detail: 'Mật khẩu không hợp lệ, vui lòng nhập lại mật khẩu',
      //     life: 2000
      //   });
      // }
      // else {
      hoaChat.value.tonKho = hoaChat.value.nhapKho;
      hoaChat.value.tinhTrang = "Còn";
        HoaChatRepository.createKho(hoaChat.value)
                .then((response) => {
                  toast.add({
                    severity: 'success',
                    summary: 'Thành công',
                    detail: 'Thêm hóa chất thành công',
                    life: 2000
                  });
                  router.push({
                    name: 'home'
                  });
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
      // };
    }
    const valid = computed(() => hoaChat.value.tenHoaChat && hoaChat.value.nhomHoaChat && hoaChat.value.hanDung && hoaChat.value.ngayNhap
         && hoaChat.value.donViDo && hoaChat.value.nhapKho);

    return {
      hoaChat,
      doRegister,
      error,
      emailPer,
      donVi,
      listLoaiHoaChat,
      valid,
    }
  }
}

