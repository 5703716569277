

  import { computed, ref } from 'vue';
  import {useToast} from "primevue/usetoast";
  import moment from 'moment';
  import {Kho} from "@/models/kho";
  import HoaChatRepository from "@/services/HoaChatRepository";

  export default {
    setup() {
    const ds = ref([] as Kho[]);
      const toast = useToast();

        HoaChatRepository.dsThuocHetHan()
            .then((response) => {
                console.log("##########$$$$$$$$$$$$$$$$$$$$$$$ HoaChat het han: " + JSON.stringify(response.data));
                ds.value = response.data;
            })
            .catch(err => {
                toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                });
            });

      const formatDateTime = (date) => {
        return moment(String(date)).format('DD/MM/YYYY');
      };

      return {
        ds,
        formatDateTime
      }
    }

  }
