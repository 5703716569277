

  import { computed, ref } from 'vue'
  import moment from 'moment';
  import {FilterMatchMode, FilterOperator} from "primevue/api";
  import router from "@/router";
  import {useToast} from "primevue/usetoast";
  import {useStore} from "vuex";
  import axios from "axios";
  import hoaChatRepository from "@/services/HoaChatRepository";
  import {useRouter} from "vue-router";
  import {DsXetNghiem} from "@/models/dsXetNghiem";
  import {BaoCaoKho} from "@/models/baoCaoKho";

  export default {
    setup() {
      const toast = useToast();
      const store = useStore();
      const list = ref([] as BaoCaoKho[]);
      const listHetHan = ref([] as BaoCaoKho[]);
      const date = ref();
      const service = ref("");
      const router = useRouter();
      const kind = ref(0);
      const fileName = ref("");
      const loadingBar = ref(false);
      const downloadFile = ref(false);

      if(!(!!store.state.token)){
        router.push({
          name: 'home'
        });
      }

          hoaChatRepository.dsBaoCaoKho()
              .then((response) => {
               list.value = response.data;
                console.log("@@@@@@@@@################ list: " + JSON.stringify(list.value));
              })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail:err.response.data,
                  life: 2500
                })});

      hoaChatRepository.dsBaoCaoKhoQuaHan()
              .then((response) => {
                listHetHan.value = response.data;
                console.log("@@@@@@@@@################ list: " + JSON.stringify(list.value));
              })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail:err.response.data,
                  life: 2500
                })});

      //selectCalendar();

      const clearCalendar = () => {
        list.value = [];
        downloadFile.value = false;
      }

      const formatDateTime = (date) => {
        if((date == null)||(date == ''))
        {
          return "chưa có thông tin"
        }
        else
        return moment(String(date)).format('DD/MM/YYYY');
      };

      const formatDateTimeCheckIn = (date) => {
        if((date == null)||(date == ''))
        {
          return "chưa có thông tin";
        }
        else
          return moment(String(date)).format('HH:mm DD/MM/YYYY');
      };

      const clearDate = () => {
        console.log("@@@@@@@@@################ clearDate");
        date.value = new Date();
      }

      const filters = ref({
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      });

      const clearFilter = () => {
        initFilters();
      };

      const initFilters = () => {
        filters.value = {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        }
      };

      const downLoadFileExport = () => {
        axios({
          url: '../api/import/FileImport/downloadListBooking/' + fileName.value,
          method: 'GET',
          responseType: 'blob',
        })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download',"DanhSachDatLich.xlsx");
              document.body.appendChild(link);
              link.click();
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })});
      };
      const expandedRows = ref();

      const onRowExpand = (event) => {
        //toast.add({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
      };
      const onRowCollapse = (event) => {
       // toast.add({severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000});
      };
      const expandAll = () => {
        expandedRows.value = list.value.filter(p => p.nhomHoaChat);
        //toast.add({severity: 'success', summary: 'All Rows Expanded', life: 3000});
      };
      const collapseAll = () => {
        expandedRows.value = [];
        //toast.add({severity: 'success', summary: 'All Rows Collapsed', life: 3000});
      };

      return {
        formatDateTime,
        list,
        date,
        clearCalendar,
        service,
        kind,
        clearDate,
        filters,
        clearFilter,
        formatDateTimeCheckIn,
        loadingBar,
        downloadFile,
        downLoadFileExport,
        expandedRows, onRowExpand, onRowCollapse, expandAll, collapseAll,
        listHetHan
      }
    }

  }
